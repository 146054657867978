import Image, { ImageProps } from "next/legacy/image";
import React, { useMemo } from "react";
import { blurHashToDataURL } from "../../../lib/blurhashToData";
interface Props extends ImageProps {
  blurHash: string | undefined;
}
export const ImageWithBlurHash: React.FC<Props> = ({
  blurHash,
  ...props
}) => {
  const blurDataUrl = useMemo(() => blurHashToDataURL(blurHash), [blurHash]);
  return <Image {...props} placeholder={blurHash ? "blur" : "empty"} blurDataURL={blurDataUrl} data-sentry-element="Image" data-sentry-component="ImageWithBlurHash" data-sentry-source-file="ImageWithBlurHash.tsx" />;
};
import clsx from "clsx";
import { ImageWithBlurHash } from "../components/ImageWithBlurHash";
import { CareStory } from "./types";
import { imageUrlFor } from "../../../lib/sanity/sanity.image";
export function CareStoryHeroCard({
  careStory,
  lazy = true,
  ...props
}: {
  careStory: CareStory;
  lazy?: boolean;
}) {
  return <div {...props} className={clsx("relative flex h-full min-h-[400px] flex-col md:min-h-[600px] lg:min-h-[800px]")} data-sentry-component="CareStoryHeroCard" data-sentry-source-file="CareStoryHeroCard.tsx">
      <ImageWithBlurHash loading={lazy ? "lazy" : "eager"} src={imageUrlFor(careStory.image)} blurHash={careStory.image?.asset?.metadata?.blurHash} objectFit="cover" objectPosition="center top" layout="fill" className="absolute inset-0 z-0" alt="" quality={100} data-sentry-element="ImageWithBlurHash" data-sentry-source-file="CareStoryHeroCard.tsx" />
      <div className="absolute inset-0 z-0 bg-gradient-to-t from-plum/80"></div>
    </div>;
}
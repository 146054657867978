import React from "react";
import clsx from "clsx";
export function Dots({
  slideCount,
  currentSlide,
  className,
  onSelect,
  variant = "light",
  ...props
}: {
  slideCount: number;
  currentSlide: number;
  className?: string;
  onSelect: (index: number) => void;
  variant?: keyof typeof variants;
}) {
  const {
    active,
    inactive
  } = variants[variant];
  return <ul className={clsx("flex flex-row items-center gap-3", className)} {...props} data-sentry-component="Dots" data-sentry-source-file="Dots.tsx">
      {Array.from({
      length: slideCount
    }).map((_, index) => <li key={index}>
          <button onClick={() => onSelect(index)} className={clsx("h-2 w-2 rounded-full", {
        [active]: index === currentSlide,
        [inactive]: index !== currentSlide
      })}></button>
        </li>)}
    </ul>;
}
const variants = {
  light: {
    active: "bg-white",
    inactive: "bg-white/20"
  },
  dark: {
    active: "bg-plum",
    inactive: "bg-plum/20"
  },
  current: {
    active: "bg-current",
    inactive: "bg-current opacity-20"
  }
};
import { toFieldPath } from "@stackbit/annotations";
import React, { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import { SharedSanitySectionProps } from "../../Layouts/types";
import { Stat } from "../Statistics/types";
import { Card } from "../components/Card";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { CareStoryHeroCard } from "./CareStoryHeroCard";
import { Dots } from "../components/Dots";
import { Metrics } from "./Metrics";
import { CareStory } from "./types";
import clsx from "clsx";
import { CareStoryCardOverlay } from "./CareStoryCardOverlay";
interface CareStoriesHeroProps extends SharedSanitySectionProps {
  careStories: CareStory[];
  bottomClassName?: string;
  metrics?: Stat[];
}
const SectionCareStoriesHero: React.FC<CareStoriesHeroProps> = ({
  annotationPrefix,
  bgClassName,
  sectionVariant = "normal",
  section_id,
  careStories = [],
  title,
  metrics,
  bottomClassName,
  fade
}) => {
  const {
    ref,
    inView
  } = useInView({
    triggerOnce: true
  });
  const [currentStoryIdx, setCurrentStoryIdx] = useState(0);
  const sliderRef = useRef<Slider>(null);
  function onSlideChange(idx: number) {
    sliderRef.current?.slickGoTo(idx);
  }
  const hasDots = careStories.length >= 2;
  const hasMetrics = metrics && metrics.length > 0;
  return <>
      <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} bottomClassName={bottomClassName} {...toFieldPath(annotationPrefix)} data-sentry-element="Section" data-sentry-source-file="SectionCareStoriesHero.tsx">
        <Container ref={ref} data-sentry-element="Container" data-sentry-source-file="SectionCareStoriesHero.tsx">
          <Card className="relative flex flex-1 overflow-hidden !px-0 !py-0 text-left text-white bg-transparent" innerClassName="flex w-full min-h-full flex-col" data-sentry-element="Card" data-sentry-source-file="SectionCareStoriesHero.tsx">
            <div {...toFieldPath(".title")} className="text-body-label absolute top-8 left-8 z-10 lg:top-16 lg:left-16">
              {title}
            </div>
            <div className="h-full w-full" {...toFieldPath(".careStories")}>
              <Slider beforeChange={(_curr, next) => setCurrentStoryIdx(next)} ref={sliderRef} infinite autoplay={true} autoplaySpeed={5000} easing="ease-in-out" speed={800} slidesToShow={1} slidesToScroll={1} className="h-full" dots={false} arrows={false} data-sentry-element="Slider" data-sentry-source-file="SectionCareStoriesHero.tsx">
                {careStories.map((careStory, i) => <CareStoryHeroCard {...toFieldPath(`.[${i}]`)} careStory={careStory} lazy={!inView}></CareStoryHeroCard>)}
              </Slider>
            </div>
            <div className="absolute bottom-8 left-8 right-8 z-10 lg:bottom-16 lg:left-16 lg:right-16 pointer-events-none">
              <CareStoryCardOverlay careStory={careStories[currentStoryIdx]} hasMetrics={hasMetrics} data-sentry-element="CareStoryCardOverlay" data-sentry-source-file="SectionCareStoriesHero.tsx" />
              {hasDots && <Dots className="mt-8 pointer-events-auto w-min" slideCount={careStories.length} currentSlide={currentStoryIdx} onSelect={idx => onSlideChange(idx)} />}
            </div>
            {hasMetrics && <Metrics {...toFieldPath(".metrics")} metrics={metrics} aria-hidden fade={fade} className="absolute bottom-8 right-8 z-10 lg:bottom-16 lg:right-16 flex-col gap-10 hidden lg:flex max-w-[256px]" />}
          </Card>
        </Container>
      </Section>
      {hasMetrics && <Metrics {...toFieldPath(".metrics")} metrics={metrics} fade={fade} className={clsx("flex-col flex lg:hidden px-4 py-6 gap-10", bottomClassName)}></Metrics>}
    </>;
};
export default SectionCareStoriesHero;
import { toFieldPath } from "@stackbit/annotations";
import clsx from "clsx";
import { Stat } from "../Statistics/types";
interface Props {
  value: Stat;
  textColor?: string;
}
export const MetricsItem: React.FC<Props> = ({
  value,
  textColor: textColor,
  ...props
}) => {
  return <div className="flex-1" {...props} data-sentry-component="MetricsItem" data-sentry-source-file="MetricsItem.tsx">
      <div className={clsx("text-e3 lg:text-e2", textColor)}>
        <span {...toFieldPath(".value")}>{value.value}</span>
        <span className="text-e4 lg:text-e3 relative top-[5px] align-top" {...toFieldPath(".symbol")}>
          {value.symbol}
        </span>
      </div>
      <div className="text-body-standard prose prose-harmony mt-2 text-current" {...toFieldPath(".description")}>
        {value.description}
      </div>
    </div>;
};
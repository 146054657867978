import React from "react";
import { Stat } from "../Statistics/types";
import { FadeUp } from "../../Fader";
import { MetricsItem } from "./MetricsItem";
import { toFieldPath } from "@stackbit/annotations";
interface Props {
  metrics?: Stat[];
  className?: string;
  fade?: boolean;
}
export const Metrics: React.FC<Props> = ({
  fade,
  metrics,
  ...props
}) => {
  return <div {...props} data-sentry-component="Metrics" data-sentry-source-file="Metrics.tsx">
      <FadeUp cascade enabled={fade} data-sentry-element="FadeUp" data-sentry-source-file="Metrics.tsx">
        {metrics?.map((value, i) => <MetricsItem {...toFieldPath(`.${i}`)} value={value} key={i} />)}
      </FadeUp>
    </div>;
};
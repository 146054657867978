import { toFieldPath } from "@stackbit/annotations";
import { Quote } from "../Quote/Quote";
import React from "react";
import { CareStory } from "./types";
import clsx from "clsx";
import { motion } from "framer-motion";
export const CareStoryCardOverlay: React.FC<{
  careStory: CareStory;
  hasMetrics: boolean;
}> = ({
  careStory,
  hasMetrics
}) => <motion.div key={careStory._key} initial={{
  opacity: 0,
  y: 10
}} animate={{
  opacity: 1,
  y: 0
}} transition={{
  duration: 0.5
}} className={clsx("relative flex h-full flex-col justify-end pointer-events-none", {
  "lg:mr-96": hasMetrics
})} data-sentry-element="unknown" data-sentry-component="CareStoryCardOverlay" data-sentry-source-file="CareStoryCardOverlay.tsx">
    <Quote.Mark className="!mx-0 !mb-0 w-10 md:w-15" data-sentry-element="unknown" data-sentry-source-file="CareStoryCardOverlay.tsx" />
    <div {...toFieldPath(".quote")} className="text-body-standard md:text-e5 mt-4 font-medium line-clamp-4">
      {careStory.quote}
    </div>
    {careStory.by && <div {...toFieldPath(".by")} className="text-body-tiny md:text-body-small mt-4 lg:mt-10">
        {careStory.by}
      </div>}
  </motion.div>;
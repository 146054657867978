import { toFieldPath } from "@stackbit/annotations";
import clsx from "clsx";
import React from "react";
import { LogoItem } from "../Logos/LogoItem";
import { Logo } from "../Logos/types";
export const Quote = {
  Container: function Quote({
    children,
    className
  }: {
    className?: string;
    children: React.ReactNode;
  }) {
    return <div className={clsx("mx-auto max-w-3xl outline-none", className)}>
        {children}
      </div>;
  },
  Mark: function Mark({
    className
  }: {
    className?: string;
  }) {
    return <svg className={clsx("mx-auto mb-6", className)} viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.4597 0.164972C10.0995 4.11009 0 18.7859 0 32.3572C0 42.1411 5.36536 47.033 11.9932 47.033C19.0944 47.033 23.1973 41.5098 23.1973 35.6711C23.1973 26.3606 12.7822 25.5715 12.7822 20.0484C12.7822 15.6298 19.8834 9.47546 26.5112 7.10838C28.7205 6.31936 29.6673 4.89911 29.0361 2.53204C28.4049 0.164971 26.3534 -0.308446 24.4597 0.164972ZM55.2317 0.164972C40.8714 4.11009 30.7719 18.7859 30.7719 32.3572C30.7719 42.1411 36.1373 47.033 42.7651 47.033C49.8663 47.033 53.9692 41.5098 53.9692 35.6711C53.9692 26.3606 43.5541 25.5715 43.5541 20.0484C43.5541 15.6298 50.6553 9.47546 57.2832 7.10838C59.4924 6.31936 60.4392 4.89911 59.808 2.53204C59.1768 0.164971 57.1253 -0.308446 55.2317 0.164972Z" fill="currentColor" />
      </svg>;
  },
  Text: function Text({
    className,
    children
  }: {
    className?: string;
    children: React.ReactNode;
  }) {
    return <div {...toFieldPath(".quote")} className={clsx("text-e6 md:text-e5 mt-2 ", className)}>
        {children}
      </div>;
  },
  Author: function Author({
    children
  }: {
    children: React.ReactNode;
  }) {
    return <div {...toFieldPath(".by")} className="text-body-small mt-8">
        — {children}
      </div>;
  },
  Logo: function Logo({
    logo
  }: {
    logo: Logo;
  }) {
    const grayscale = logo.applyGrayscale === undefined || logo.applyGrayscale;
    return <LogoItem logo={logo} logoColors={grayscale ? "grayscale" : "colors"} variant="transparent" {...toFieldPath(".logo")} />;
  }
};